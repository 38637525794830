import { ApplicationPage } from "@prisma/client"
import { z } from "zod"

export const inverterOverviewPageConfigSchema = z.object({
  controlSwitchSection: z
    .object({
      type: z.enum(["fullControls", "switchOnly", "singleSwitchWithConditions", "multiSwitchWithConditions"]),
    })
    .nullable(),
  inverterConditionsSection: z
    .object({
      cardTitleType: z.enum(["monitoring", "disponibility"]),
    })
    .nullable(),
  hasCurrentPriceCard: z.boolean().optional(),
})
export type InverterOverviewPageConfig = z.infer<typeof inverterOverviewPageConfigSchema>

export const inverterStatsPageConfigSchema = z.object({
  inverterMonthlyStats: z
    .object({
      tableLabelsType: z.enum(["monitoring", "disponibility"]),
    })
    .nullable(),
  commandsStats: z
    .array(
      z.object({
        shouldShowCommandResults: z.boolean(),
        commandPricesType: z.enum(["full", "simple"]).nullable(),
        labelsType: z.enum(["commands", "flexibility", "control"]),
        commandsType: z.enum(["all", "flexibility", "solarCurtailmentOnly"]),
      }),
    )
    .nullable(),
})

export type InverterStatsPageConfig = z.infer<typeof inverterStatsPageConfigSchema>

export const inverterSettingsPageConfigSchema = z.object({
  inverterPositionInputType: z.enum(["addressAutocomplete", "postalCode"]),
  shouldDisplayAmortizationField: z.boolean(),
  hasTermsStepBeforeEnablingControl: z.boolean(),
  shouldShowConnectToMyDelta: z.boolean(),
  controlSwitchContentType: z.enum(["manualAndAlgorithm", "commands", "serviceAopzp"]),
  cleanupModalContentType: z.enum(["defaultValuesList", "rewardConditions", "serviceAopzp"]),
})

export type InverterSettingsPageConfig = z.infer<typeof inverterSettingsPageConfigSchema>

export const inverterControlPlanPageConfigSchema = z.null()

export type InverterControlPlanPageConfig = z.infer<typeof inverterControlPlanPageConfigSchema>

export const tenantCommonPageConfigSchema = z.object({
  hasTopHeader: z.boolean().optional().default(true),
})

export type TenantCommonPageConfig = z.infer<typeof tenantCommonPageConfigSchema>

export const tenantPageConfigSchemas = {
  [ApplicationPage.COMMON]: tenantCommonPageConfigSchema,
  [ApplicationPage.INVERTER_OVERVIEW]: inverterOverviewPageConfigSchema,
  [ApplicationPage.INVERTER_STATS]: inverterStatsPageConfigSchema,
  [ApplicationPage.INVERTER_SETTINGS]: inverterSettingsPageConfigSchema,
  [ApplicationPage.INVERTER_CONTROL_PLAN]: inverterControlPlanPageConfigSchema,
} satisfies Record<ApplicationPage, z.Schema>

export type TenantPageConfigSchemas = typeof tenantPageConfigSchemas
